import DaBarIcon from './digital-bar.png'
import DigitalWatchdogIcon from './digital-watchdog2.png'
import SmartOffersIcon from './smart-offers.png'
import EcoSignageIcon from './ecosignage.png'
import PlugTurnIcon from './plugturn.svg'

const products = [
  {
    name: 'Digital Bar',
    slug: 'digital-bar',
    icon: DaBarIcon,
    url: 'https://admin.dabarapp.com',
  },
  {
    name: 'Digital Watchdog',
    slug: 'digital-watchdog',
    icon: DigitalWatchdogIcon,
    url: 'https://digital-watchdog.dev.exevio.hr',
  },
  {
    name: 'Smart Offers',
    slug: 'smart-offers',
    icon: SmartOffersIcon,
    url: 'https://admin.smart-offers.dev.exevio.hr/',
  },
  {
    name: 'PlugTurn',
    slug: 'plugturn',
    icon: PlugTurnIcon,
    url: 'https://admin.plugturn.com/',
  },
  {
    name: 'Hello Signage',
    slug: 'hello-signage',
    icon: null,
    url: 'https://backoffice.hello-signage.devpc.exevio.com/',
  },
  {
    name: 'ecoSignage',
    slug: 'ecosignage',
    icon: EcoSignageIcon,
    url: 'https://admin.ecosignage.dev.exevio.hr',
  },
  {
    name: 'SmartRamp',
    slug: 'smartramp',
    icon: null,
    url: 'https://api.smartramp.dev.exevio.hr',
  },
]
export default products
