<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        variant="outlined"
        append-icon="mdi-menu-down"
        v-on="on"
      >
        <v-avatar
          rounded="0"
          size="1rem"
          class="pr-3"
        >
          <v-img :src="activeServiceEntry.icon"></v-img>
        </v-avatar>

        {{ activeServiceEntry.name }}

        <v-icon
          right
        >
          {{ icons.mdiChevronDown }}
        </v-icon>
      </v-btn>
    </template>

    <v-list
      density="compact"
    >
      <v-list-item
        v-for="(service, index) in otherServices"
        :key="service.slug"
        :value="index"
        :href="service.url"
        target="_blank"
      >
        <v-list-item-avatar
          size="1rem"
          rounded="0"
        >
          <v-img :src="service.icon"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ service.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'
import services from './assets/service-list'

export default {
  name: 'GhServiceSwitcher',
  tag: 'GhServiceSwitcher',

  props: {
    activeService: {
      type: String,
      required: true,
      default: '',
    },
  },

  computed: {
    activeServiceEntry() {
      const entry = services.find(service => service.slug === this.activeService)

      if (entry === undefined) {
        return null
      }

      return entry
    },

    otherServices() {
      return services.filter(service => service.slug !== this.activeService)
    },
  },

  setup() {
    return {
      icons: {
        mdiChevronDown,
      },
    }
  },
}
</script>
